import * as React from "react"
import Layout from "../../components/layout"
import Star from "../../components/star"
import '../../style.css'

const Glider = () => {

    const info={
        title:'The Swift Glider',
        image:'glider.png',
        page:'glider',
        description:'The Swift Satellite is named for an agile bird that catches its food on the fly. Now you can build a paper airplane designed to look like a Swift! The kit comes with pre-printed heavy-duty colored paper, and assembly only takes a few minutes.',
        internalText:["The Swift Satellite is named for an agile bird that catches its food on the fly. Now you can build a paper airplane designed to look like a Swift! The kit comes with pre-printed heavy-duty colored paper, and assembly only takes a few minutes."],
        pdf:{
          pdf:'swiftGlider.pdf',
          accessible:'swiftGliderDisability.pdf'
        }
    }

  return (
    <Layout>
        <div>
          <h1>{info.title}</h1>
          <Star/>
          <p>{info.internalText}</p>
          {info.pdf.accessible?
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a> and <a href={`/resourceLinks/${info.pdf.accessible}`} target='_blank' rel="noreferrer">Disability accessible PDF</a></p>
          :
          <p>Check out the <a href={`/resourceLinks/${info.pdf.pdf}`} target='_blank' rel="noreferrer">PDF</a></p>
          }        
        </div>
    </Layout>
  )
}

export default Glider